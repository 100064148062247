<template>
	<main class="sport" :class="`sport--${sport_slug}`">
		<slideshow
			template="SlideshowBanner"
			:section-title="`${sport.name} Banner`"
			:slideshowId="1"
			:count="1"
			:sportId="sport.id"
			v-if="sport && sport.id"
		/>
		<championships
			class="padding-t-32 bp-540:padding-t-16 bp-1024:padding-x-48 bp-1024:padding-t-48 bp-1024:padding-b-32"
			section-title="Championships"
			:sportSlug="sport_slug"
			template="ChampionshipsList"
			sort-order="division"
		/>
	</main>
</template>

<script>
import { set_external_links } from '../helpers';
import Championships from '@/components/championships/Championships';
import Slideshow from '@/components/slideshow/Slideshow';

export default {
	name: 'Sport',

	components: {
		Championships,
		Slideshow,
	},

	computed: {
		sport_slug() {
			return this.$route.params.slug;
		},
		sport() {
			let _sport = this.$store.getters.get_sport_from_slug(
				this.sport_slug,
			);

			if (_sport) {
				this.setMetaSidearm(`${_sport.title} Tickets`, [
					{
						name: 'description',
						content: `Buy and sell ${_sport.title} tickets for every game on the road to the NCAA title. Includes hospitality options, dates, schedules, and locations`,
					},
					{
						name: 'og:title',
						content: `${_sport.title} Tickets`,
					},
					{
						name: 'og:description',
						content: `Buy and sell ${_sport.title} tickets for every game on the road to the NCAA title. Includes hospitality options, dates, schedules, and locations`,
					},
					{
						name: 'twitter:title',
						content: `${_sport.title} Tickets`,
					},
					{
						name: 'twitter:description',
						content: `Buy and sell ${_sport.title} tickets for every game on the road to the NCAA title. Includes hospitality options, dates, schedules, and locations`,
					},
				]);
			}

			return _sport;
		},
	},
};
</script>
